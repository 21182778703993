html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
}

#map {
    height: 100%;
    width: 100%;
    position: absolute;
    /* Ensure it covers the entire page */
}

.map-overlay {
    position: absolute;
    top: 110px;
    /* Adjust based on header height */
    left: 10px;
    background-color: white;
    padding: 10px;
    border-radius: 4px;
    z-index: 1;
    opacity: 70%;
}

.App {
    height: 100%;
    /* Make sure the App container also takes up full height */
    position: relative;
}

div {
    text-align: left;
}