body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

#map {
  /* margin: 20px auto; */
  width: 80%;
  height: 500px;
}
